import { render, staticRenderFns } from "./lingchong-header.vue?vue&type=template&id=cbc0692e&"
import script from "./lingchong-header.vue?vue&type=script&lang=js&"
export * from "./lingchong-header.vue?vue&type=script&lang=js&"
import style0 from "./lingchong-header.vue?vue&type=style&index=0&id=cbc0692e&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports