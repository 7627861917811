<template>
  <div class="lingchong-home">
    <div class="main-img">
      <img src="@/assets/main-p1.jpg">
    </div>
    <div class="service-title">
      善终服务
    </div>
    <div class="swiper-container service-box">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <router-link to="">
            <div class="service-img"><img src="@/assets/s1.jpg"></div>
            <div class="service-text">小宠方案&nbsp;￥799</div>
            <div class="service-text">EXQUISITE&nbsp;PET</div>
          </router-link>
        </div>
        <div class="swiper-slide">
          <router-link to="">
            <div class="service-img"><img src="@/assets/s2.jpg"></div>
            <div class="service-text">灵爱方案&nbsp;￥499</div>
            <div class="service-text">SOUL&nbsp;AI</div>
          </router-link>
        </div>
        <div class="swiper-slide">
          <router-link to="">
            <div class="service-img"><img src="@/assets/s3.jpg"></div>
            <div class="service-text">灵极方案&nbsp;￥1699</div>
            <div class="service-text">SOUL&nbsp;JI</div>
          </router-link>
        </div>
        <div class="swiper-slide">
          <router-link to="">
            <div class="service-img"><img src="@/assets/s4.jpg"></div>
            <div class="service-text">灵宠方案&nbsp;￥5200</div>
            <div class="service-text">SOUL&nbsp;CHONG</div>
          </router-link>
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <div class="brand-box">
      <div class="brand-img">
        <img src="@/assets/bp3.jpg" alt="">
      </div>
      <div class="brand-text">
        <div class="brand-title">
          SOULPET品牌
        </div>
        <div class="brand-content">
          SOULPET作为宠物善终高端奢侈品牌，旗下拥有宠物项链纪念饰品、生命钻石、宠物油画、宠物克隆等业务，以 “给它上天般的宠爱” 为品牌理念，目前在全国均设有服务网点，全门市采用LIGHT
          LUXURY风格设计，引进JW集团香氛处理系统并由奢侈高定灯光师进行门市区域灯光调节，将服务细节、门市打造等进行优化，耗资百万仅为让美好变得更加珍贵，让服务过程变得更细致且独特。
        </div>
        <div class="brand-btn">
          <router-link to=""><button>探索更多内容</button></router-link>
        </div>
      </div>
    </div>
    <div class="product-box">
      <div class="product-title">
        灵宠产品
      </div>
      <ul class="product-img">
        <li><router-link to="/product1"><img src="@/assets/product1.jpg" alt=""></router-link></li>
        <li><router-link to=""><img src="@/assets/product2.jpg" alt=""></router-link></li>
        <li><router-link to=""><img src="@/assets/product3.jpg" alt=""></router-link></li>
      </ul>
      <div class="product-btn">
        <button>选购更多产品</button>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
  name: 'lingchong-home',
  mounted() {
    new Swiper(".swiper-container", {
      slidesPerView: 3,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    });
  }

}


</script>

<style lang="less" scoped>
.lingchong-home {
  .main-img {
    width: 1349px;
    height: 459px;
    margin: 0px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .service-title {
    letter-spacing: .3em;
    font-size: 28px;
    padding: 30px 0px;
    text-align: center;
    color: black;
  }

  .service-box {
    width: 1149px;
    height: 500px;
    margin: 0px auto;
  }

  .service-img {
    img {
      width: 370px;
      height: 320px;
    }

    text-align:center;
    margin-bottom:30px;
  }

  .service-text {
    text-align: center;
  }
}

.swiper-container {
  --swiper-navigation-color: #73726e;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 24px;
  /* 设置按钮大小 */
}

.brand-box {
  display: flex;
  margin: 0px auto;
  width: 1170px;
  height: 400px;
  justify-content: center;

  .brand-img {
    height: 400px;

    img {
      width: 635px;
      height: 400px;
    }
  }

  .brand-text {
    width: 32%;
    height: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    .brand-title {
      font-size: 28px;
      color: black;
    }

    .brand-content {
      font-size: 16px;
      margin: 30px 0px;
    }

    .brand-btn {
      position: absolute;
      bottom: -10px;

      button {
        border: 1px solid #333;
        background-color: white;
        text-align: center;
        padding: 10px 20px;
        width: 170px;
        margin-top: 35px;
      }

    }
  }
  
}
.product-box{
  width:1349;
  height:624px;
  margin:40px auto 0px;
  .product-title{
    font-size: 25px;
    color: #000;
    text-align: center;
    padding: 35px 0 25px;
    font-weight: 500;
  }
  .product-img{
    display: flex;
    justify-content: center;
    margin:30px 0px;
    li{
      margin:0px 5px;
    }
    img{
      width:373px;
      height:340px;
    }
  }
  .product-btn{
    button{
      display: block;
      border: 1px solid #333;
      text-align: center; 
      padding: 10px 20px;
      width: 170px;
      margin: 35px auto 0;
    }
   
  }
}
</style>