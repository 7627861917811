<template>
    <div class="lingchong-header">

        <div class="lingchong-header-container">
            <ul class="header-icon">
                <li><router-link to=""><i class="iconfont icon-location"></i></router-link></li>
                <li><router-link to=""><i class="iconfont icon-mail"></i></router-link></li>
                <li style="border:0px;"><router-link to=""><i class="iconfont icon-search"></i></router-link></li>
            </ul>
            <div class="header-logo">
                SOULPET
            </div>
        </div>

        <div class="nav" @mouseleave="activeTab = 0">
            <ul class="nav-tab">
                <li><router-link to="/home" @mouseenter.native="activeTab = 1">SOULPET品牌</router-link></li>
                <li><router-link to="" @mouseenter.native="activeTab = 2">资讯</router-link></li>
                <li><router-link to="/service" @mouseenter.native="activeTab = 3">善终服务</router-link></li>
                <li><router-link to="" @mouseenter.native="activeTab = 4">特色服务</router-link></li>
            </ul>
            <!-- <div class="nav-preview" v-show="activeTab == 1">
                <div class="nav-container">
                    <ul class="about-box preview-box">
                        <li><router-link to="">关于灵宠</router-link></li>
                        <li><router-link to="">灵宠历史</router-link></li>
                        <li><router-link to="">品牌于标示</router-link></li>
                        <li><router-link to="">善终高端礼遇</router-link></li>
                        <li><router-link to="">企业社会责任</router-link></li>
                        <li class="li-img"><img src="@/assets/bp1.jpg"></li>
                    </ul>
                    <ul class="service-box preview-box">
                        <li><router-link to="">服务</router-link></li>
                        <li><router-link to="">宠物善终24H</router-link></li>
                        <li><router-link to="">寻找灵宠门店</router-link></li>
                        <li><router-link to="">保养及检修</router-link></li>
                        <li><router-link to="">联系我们</router-link></li>
                        <li  class="li-img"><img src="@/assets/bp2.jpg"></li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</template>
<style lang="less">
.lingchong-header {
    
    .lingchong-header-container {
        width: 1349px;
        margin: 0px auto;
        
    }

    .header-icon {
        height: 35px;
        line-height: 24px;
        padding-left: 20px;
        padding-top: 10px;

        li {
            float: left;
            border-right: 1px solid #e0e0e0;
            text-align: center;
        }

        i {
            font-size: 24px;
        }

        a {
            display: block;
            height: 24px;
            width: 70px;
        }
    }

    .header-logo {
        width: 1349px;
        height: 50px;
        font-size: 45px;
        font-weight: 600;
        color: black;
        text-align: center;
        letter-spacing: 0.3em;
    }

    .nav-tab {
        margin: 0px auto;
        width: 1349px;
        height: 78px;
        border-bottom: 1px solid #e6e6e6;
        li {
            text-align: center;
            float: left;
            margin-right: 10px;
        }

        a {

            display: block;
            height: 78px;
            width: 320px;
            font-size: 18px;
            line-height: 78px;
        }
    }

    .nav-preview {
        background-color: #f0ede8;
        height: 430px;

        .nav-container {
            height:430px;
            width:1349px;
            margin: 0px auto;
            ul {
                margin-left: 250px;
                width: 165px;
                height: 265px;
                padding: 50px 50px;
                background-color: white;
                position: relative;
                img {
                    width: 178px;
                    height: 234px;
                }
                li{
                    margin-bottom: 10px;
                }
                li a:hover{
                    text-decoration: underline;
                }
                :first-child a{
                    font-size: 18px;
                }
                .li-img{
                    position: absolute;
                    top: 50%; /* 将子元素相对于父元素垂直居中 */  
                    transform: translateY(-50%); /* 使用transform属性将子元素向上移动自身高度的一半，使其完全垂直居中 */
                    left:-145px;
                }
            }

            

            .preview-box {
                margin-top: 30px;
                float: left;
            }
        }

    }


}
</style>
<script>
export default {
    data() {
        return {
            activeTab: 0,
        }
    },
    methods: {
    }
}
</script>

