<template>
  <div class="service-box">

    <div class="service-header">
      <div class="header-title">
        善终服务
      </div>
      <div class="header-img">
        <img src="@/assets/dog-girl.jpg" alt="">
      </div>
    </div>
    <!-- 宠物善终方案 -->
    <div class="service-method">
      <div class="method-title">SOULPET宠物善终方案</div>
      <div class="method-text">它们陪伴了我们10000年，一生的等待都只为见你一面，如今我将给它上天般的宠爱</div>
      <div class="method-more"><router-link to=""><button>探索更多方案</button></router-link></div>
    </div>
    <img class="rose" src="@/assets/rose.jpg" alt="">
    <serviceMethodCard cardtitle="EXQUISITE PET 小宠方案 ¥799" cardtext="精致小宠 偏爱无边" buttontext="探索 小宠方案 ￥799"
      imgpath="../../s1.jpg" topath='/xiaochong' />
    <serviceMethodCard cardtitle="SOUL AI 灵爱方案 ¥499" cardtext="你的慈祥是我这生的挚爱，愿来世还能再得你宠爱" buttontext="探索 灵爱方案 ¥499"
      imgpath="../../s2.jpg" topath='/tianai' reverse="true" />
    <serviceMethodCard cardtitle="SOUL JI 灵极方案 ¥1699" cardtext="无悔跨越天极，将继续在远方守护" buttontext="探索 灵极方案 ¥1699"
      imgpath="../../s3.jpg" topath='/tianji' />
    <serviceMethodCard cardtitle="SOUL CHONG 灵宠方案 ¥5200" cardtext="给它上天般的宠爱" buttontext="探索 灵宠方案 ¥5200"
      imgpath="../../s4.jpg" topath='/tianchong' reverse="true" />
  </div>
</template>

<script>
import serviceMethodCard from '@/components/service-method-card.vue';
export default {
  data() {
    return {}
  },
  components: {
    serviceMethodCard
  }
}
</script>

<style lang="less" scoped>
.service-box {
  width: 1340px;
  margin: 0px auto;

  .service-header {
    .header-title {
      font-size: 26px;
      padding: 20px 0;
      text-align: center;
      color: #333;
      letter-spacing: 3px;

    }

    .header-img {
      text-align: center;

      img {
        width: 1349px;
        height: 369px;
      }

    }
  }

  //方案
  .service-method {
    margin: 60px auto 20px;

    .method-title {
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 2px;
      text-align: center;
    }

    .method-text {
      font-size: 18px;
      padding: 25px 0;
      text-align: center;
    }

    .method-more {
      button {
        font-size: 12px;
        display: block;
        border: 1px solid #000;
        text-align: center;
        width: 215px;
        padding: 15px 0;
        margin: 0 auto 50px;
      }
    }
  }

  .rose {
    width: 100%;
    height: 347px;
    display: block;
    margin: 40px auto;
  }
}
</style>