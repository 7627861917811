import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/lingchong-home.vue'
import Service from '@/views/lingchong-service.vue'
import Xiaochong from '@/views/service-xiaochong.vue'
import Tianai from '@/views/service-tianai.vue'
import Tianji from '@/views/service-tianji.vue'
import Tianchong from '@/views/service-tianchong.vue'
import ShopLocation from '@/views/shop-location.vue'
import Product1 from '@/views/product-1.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },{
    path: '/service',
    name: 'service',
    component: Service
  },{
    path: '/xiaochong',
    name: 'xiaochong',
    component: Xiaochong
  },{
    path: '/tianai',
    name: 'tianai',
    component: Tianai
  },{
    path: '/tianji',
    name: 'tianji',
    component: Tianji
  },{
    path: '/tianchong',
    name: 'tianchong',
    component: Tianchong
  },{
    path: '/shoplocation',
    name: 'shoplocation',
    component: ShopLocation
  },{
    path: '/product1',
    name: 'product1',
    component: Product1
  },{
    path: '/',
    redirect: '/home',
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),//每次都跳转到顶部
})

export default router
