<template>
  <div class="detail">
    <img src="@/assets/s4-detail.jpg" alt="">
    
  </div>
</template>

<script>
export default {

}
</script>

<style>
 .detail{
  text-align: center;
 }
</style>