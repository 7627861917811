<template>
  <div class="detail">
    <img src="@/assets/s1-detail.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>
 .detail{
  text-align: center;
 }
</style>