<template>
    <div class="product-box">

        <div class="product-show">
            <div class="product-img swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><img src="@/assets/big-statue1.jpg"></div>
                    <div class="swiper-slide"><img src="@/assets/big-statue2.jpg"></div>
                    <div class="swiper-slide"><img src="@/assets/big-statue3.jpg"></div>
                </div>

            </div>
            <div class="swiper-thumbs">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" :class="{ active: activeSlide == 0 }"><img src="@/assets/big-statue1.jpg"></div>
                    <div class="swiper-slide" :class="{ active: activeSlide == 1 }"><img src="@/assets/big-statue2.jpg"></div>
                    <div class="swiper-slide" :class="{ active: activeSlide == 2 }"><img src="@/assets/big-statue3.jpg"></div>
                </div>
            </div>
        </div>

        <ul class="product-ul">
            <li class="product-serial">灵宠产品系列</li>
            <li class="product-title">灵宠产品</li>
            <li class="code-num">编号：比熊犬</li>
            <li class="intrduce">让逝去的生命，焕发出新的可能。萌宠骨灰石膏雕塑，或许它不会说话，但它会让你感觉到从未离开，它一直都在！</li>
            <li class="price">¥ 3520.00</li>
            <li class="attribute">尺寸：宽17.7CM/长26.6CM/高（连外框）24CM</li>
            <li class="attribute">颜色：白色</li>
            <li class="welcome-contact">*欢迎在线咨询客服人员，了解更多产品参数</li>
            <li class="customize"><router-link to="">善终产品高端定制<br>敬请注意</router-link></li>
            <li class="online-contact"><button>在线咨询</button></li>
            <li class="search-near">寻找附近的店铺</li>
        </ul>
    </div>
</template>

<script>
import Swiper from 'swiper'
export default {
    data() {
        return {
            activeSlide: 0
        }
    }, mounted() {
        var _this = this;
        new Swiper('.swiper-container', {
            on: {
                slideChangeTransitionStart: function () {
                    _this.activeSlide = this.activeIndex
                },
            },
            thumbs: {
                swiper: {
                    el: '.swiper-thumbs', //注意此处的设置方式
                    slidesPerView: 3,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    on: {
                        slideChangeTransitionStart: function () {
                            alert(this.activeIndex)
                        },
                    },
                }
            }
        })
    }
}
</script>

<style lang="less" scoped>
.active {
    border: 1px solid #ae9477;
}

.product-box {
    --swiper-navigation-color: black;
    --swiper-navigation-size: 18px;
    height: 731px;
    position: relative;
    background-color: #f0ede8;
    margin-bottom: 10px;
    padding-top: 60px;
    display: flex;
    justify-content: center;

    .product-show {
        width: 540px;
    }

    .product-img {
        width: 540px;
        height: 456px;

        img {
            width: 540px;
            height: 456px;
        }
    }

    .swiper-thumbs {
        width: 240px !important;

        .swiper-slide {
            margin: 0px 10px;
        }

        img {
            width: 78px;
            height: 71px;
        }
    }

    .product-ul {
        width: 570px;
        height: 671px;

        li {
            text-align: center;
            width: 380px;
            margin: 0px auto;
        }

        .product-serial {
            color: #85817e;
            font-size: 13px;
            line-height: 2;
        }

        .product-title {
            color: #000;
            font-size: 22px;
            line-height: 1.2;
        }

        .code-num {
            color: #85817e;
            font-size: 12px;
            line-height: 2;
        }

        .intrduce {
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 25px;
            margin-top: 20px
        }

        .price {
            line-height: 77px;
            letter-spacing: 2px;
            font-size: 14px;
        }

        .attribute {
            width: 380px;
            border: 1px solid #000;
            padding: 10px 25px;
            font-size: 12px;
            text-align: left;
            margin: 5px auto;
        }

        .welcome-contact {
            padding: 5px 0;
            font-size: 14px;
            margin-top: 10px;
        }

        .customize {
            font-size: 12px;
            line-height: 24px;
            text-decoration: underline;
            text-align: center;
            padding: 15px;
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            display: block;
            margin: 15px auto 50px;
        }

        .online-contact {
            button {
                width: 380px;
                height: 46px;
                line-height: 46px;
                font-size: 14px;
                text-align: center;
                background: #000;
                color: #fff;
                margin: 0 auto;
                display: block;
            }

        }

        .search-near {
            color: #000000;
            text-decoration: underline;
            text-align: center;
            display: block;
            padding: 15px 0;
            font-size: 13px;
        }

    }
}</style>