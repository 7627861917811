<template>
    <div class="lingchong-footer">
        <div class="search-near-box">
            <ul class="form-box">
                <li class="form-title">寻找附近门店</li>
                <li class="form-sel">
                    <div>
                        <select class="form-select" name="" id="">
                            <option>国家/地区</option>
                            <option>中国大陆</option>
                        </select>
                    </div>
                    <div>
                        <select class="form-select"  name="" id="">
                            <option>全部城市</option>
                            <option>长沙</option>
                        </select>
                    </div>
                </li>
                <li class="form-search">
                    <router-link to="/shoplocation"><button >搜索</button></router-link>
                </li>
            </ul>
        </div>
        <div class="footer-info">
            <div class="footer-info-box">
                <ul>
                    <li><router-link to="">产品系列</router-link></li>
                    <li><router-link to="">骨灰盅</router-link></li>
                    <li><router-link to="">骨灰纪念品</router-link></li>
                    <li><router-link to="">骨灰石膏</router-link></li>
                    <li><router-link to="">生命晶石</router-link></li>
                    <li><router-link to="">高级钻石</router-link></li>
                    <li><router-link to="">定制丝巾</router-link></li>
                    <li><router-link to="">宠物克隆</router-link></li>
                </ul>
                <ul>
                    <li><router-link to="">善终服务</router-link></li>
                    <li><router-link to="">EXQUSITE PET 小宠方案￥799</router-link></li>
                    <li><router-link to="">SOUL&nbsp;AI灵爱方案￥499</router-link></li>
                    <li><router-link to="">SOUL&nbsp;JI灵爱方案￥1699</router-link></li>
                    <li><router-link to="">SOUL&nbsp;CHONG灵宠方案￥5200</router-link></li>
                </ul>
                <ul>
                    <li><router-link to="">关于SOULPET</router-link></li>
                    <li><router-link to="">SOULPET灵宠介绍</router-link></li>
                    <li><router-link to="">品牌与标示</router-link></li>
                    <li><router-link to="">善终高端礼遇</router-link></li>
                    <li><router-link to="">企业社会责任</router-link></li>
                </ul>
                <ul>
                    <li><router-link to="">门市</router-link></li>
                    <li><router-link to="">查找门市</router-link></li>
                </ul>
            </div>
            <div class="footer-logo">
                <div class="footer-logo-font">
                    SOULPET
                </div>
            </div>
            <hr>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.lingchong-footer {
    .search-near-box {
        padding: 100px 0;
        background: #f0ede8;

        .form-box {
            width: 636px;
            margin: 0px auto;

            .form-title {
                font-size: 25px;
                color: #000;
                text-align: center;
                padding: 0;
                letter-spacing: 8px;
                font-weight: 500;
            }
            .form-sel{
                select{
                    border-radius: 0px;
                }
                div{
                    padding:40px 10px;
                    width: 50%;
                    float:left;
                }
            }
            .form-search{
                button{
                    width: 320px;
                    height: 42px;
                    border: 1px solid #333;
                    text-align: center;
                    line-height: 42px;
                    margin: 0 auto;
                    background: #f0ede8;
                    display: block;
                }
            }
        }
    }
    .footer-info{
        background-color: #1d1d1f;
        height:510px;
        .footer-info-box{
            width: fit-content;
            margin: 0px auto;
            height:395px;
            background-color: #1d1d1f;
            ul{
                float:left;
                width: 214px;
                padding: 80px 15px 0px;
                li{
                    margin: 8px 0px;
                }
                li a{
                    color:white;
                    font-size: 12px;
                }
                :first-child a{
                    color:#5A5A5D;
                    font-size: 12px;
                }
            
            }

        }
        .footer-logo{
            position: relative;
            .footer-logo-font{
                color:white;
                width:130px;
                height:30px;
                background-color: #1d1d1f;
                font-size: 18px;
                letter-spacing: 0.3em;
                position: absolute;
                left:50%;
                text-align: center;
                line-height: 30px;
                transform: translateX(-50%);
                z-index: 20;
            }
            
        }
    }
}
</style>