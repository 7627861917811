<template>
    <!-- mbAy0wD2CTS1c1klvEGP5hbsf8ZoshFh -->
    <div class="shop-container">
        <div class="shop-title">
            宠物高端善终品牌灵宠
        </div>
        <ul class="shop-form">
            <li><button class="location-btn">查看您的位置</button></li>
            <li>
                <select id="" class="country-select">
                    <option>国家/地区</option>
                    <option>中国大陆</option>
                </select>
            </li>
            <li>
                <select id="" class="country-select">
                    <option>全部城市</option>
                    <option>长沙</option>
                </select>
            </li>
            <li>
                <button class="search-btn">搜索</button>
            </li>
        </ul>
        <div id="map-container" class="map">
            
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        var map =new BMapGL.Map("map-container");
        var point = new BMapGL.Point(116.404, 39.915);
        map.centerAndZoom(point, 15); 
    }
}
</script>

<style lang="less" scoped>
.shop-container {
    width: 1349px;
    margin: 0px auto;

    .shop-title {
        font-size: 25px;
        color: #000;
        text-align: center;
        padding: 0 0 20px;
        letter-spacing: 8px;
        font-weight: 500;
        margin: 40px 0px;
    }

    .shop-form {
        height: 144px;
        display: flex;
        justify-content: center;

        li {
            margin: 0px 8px;
        }

        .location-btn {
            width: 196px;
            height: 44px;
            border: 1px solid #000;
            text-align: center;
            line-height: 44px;
            background: #000;
            display: inline-block;
            color: #fff;
            vertical-align: middle;
        }

        .country-select {
            height: 44px;
            line-height: 44px;
            width: 320px;
            vertical-align: middle;
        }

        .search-btn {
            width: 80px;
            height: 44px;
            border: 1px solid #000;
            text-align: center;
            line-height: 44px;
            background: #000;
            display: inline-block;
            color: #fff;
            vertical-align: middle;
        }
    }
    .map{
        width: 1138px;
        height:548px;
        margin: 0px auto 20px;
    }
}
</style>