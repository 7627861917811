<template>
  <div id="app">
    <lingchong-header></lingchong-header>
    <router-view></router-view>
    <lingchong-footer></lingchong-footer>
  </div>
</template>

<script>
import lingchongHeader from '@/components/lingchong-header.vue';
import lingchongFooter from './components/lingchong-footer/lingchong-footer.vue';
export default {
  data(){
    return{}
  },components:{
    'lingchong-header':lingchongHeader,
    'lingchong-footer':lingchongFooter,
  }
}
</script>



<style type="text/css">
    
</style>
