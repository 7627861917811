<template>
    <div class="service-method-card" :class="{changedirection:reverse}">
        <div class="card-img">
            <img :src="imgpath" alt="">
        </div>
        <div class="card-content">
            <div class="card-title">{{ cardtitle }}</div>
            <div class="card-text">{{ cardtext }}</div>
            <div class="card-btn"><router-link :to="topath"><button>{{ buttontext }}</button></router-link></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: ['cardtitle', 'cardtext', 'buttontext', 'topath', 'imgpath', 'reverse'],
}
</script>

<style lang="less" scoped>
.changedirection{
    flex-direction: row-reverse;
}
.service-method-card {
    margin:40px 0px;
    display: flex;
    justify-content: center;

    .card-img {
        width: 635px;
        img {
            width: 635px;
            height: 377px;
        }
    }

    .card-content {
        width:25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .card-title {
            font-size: 24px;
            color: #000;
            text-align: center;
        }

        .card-text {
            font-size: 18px;
            color: #000;
            padding: 25px 0;
            text-align: center
        }

        .card-btn {
            button {
                font-size: 12px;
                display: block;
                border: 1px solid #000;
                text-align: center;
                width: 215px;
                padding: 15px 0;
                margin: 0 auto;
            }
        }
    }
}</style>